<template>
  <div class="auth-full-page h-100-vh">
    <!-- <Content/> -->
    <b-container class="h-100-vh">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </b-container>
    <!-- <notifications/> -->
  </div>
</template>

<script>
// import Navbar from '@/components/Navbar'
// import Content from './Content'

export default {
  name: 'general-screen-dashboard',
  // components: {
  //   Navbar,
  //   Content
  // }
}
</script>
