<template>
    <div>
      <b-navbar id="main-navbar">
            <b-navbar-brand>
                <div class="d-flex align-items-center">
                    <font-awesome-icon icon="bars" class="icon" style="cursor: pointer;" @click.prevent="$emit('onToggleClick')"/>
                    <h3 class="m-0">{{ $route.meta.title ? $route.meta.title : $route.name }}</h3>
                </div>
            </b-navbar-brand>
            <b-navbar-nav id="nav-dropdown" class="navbar-nav-icons flex-row order-1 order-lg-2">
                <b-nav-item-dropdown size="lg" right no-caret toggle-class="text-decoration-none rounded-circle toggle-icon" menu-class=" position-absolute" variant="link">
                    <template #button-content>
                        <!-- <font-awesome-icon icon="user-circle" /> -->
                        <div class="user-initial bg-light text-primary">{{$store.getters['account/accountData'].name.substring(0, 3)}}</div>
                    </template>
                    <b-dropdown-item to="/setting">Pengaturan Akun</b-dropdown-item>
                    <b-dropdown-item @click.prevent="logOut">Sign Out</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
       </b-navbar>
    </div>
</template>

<script>
export default {
    data () {
      return {
      }
    },
    computed: {
    },
    methods: {
        logOut () {
            this.$swal.fire({
                title: 'Anda Yakin Akan Log Out?',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
            }).then(result => {
                if (result.value) {
                    this.$router.push('/logout')
                }
            })
        }
    },
    mounted () {
        // this.$nextTick(() => {
        //     window.addEventListener('scroll', () => {
        //         if (this.$store.getters['account/isLoggedIn']) {
        //             let scrollpos = window.scrollY
        //             if (scrollpos > 0) {
        //                 document.querySelector('#large-nav').classList.add('shadow')
        //             } else {
        //                 document.querySelector('#large-nav').classList.remove('shadow')
        //             }
        //         }
        //     })
        // })
    }
}
</script>