<template>
  <div id="main-content">
  <b-container class="scollable">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </b-container>
</div>  
</template>

<script>
  export default {
    
  }
</script>