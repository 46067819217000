<template>
  <div class="multiple-container" v-bind:class="{ 'minimize-nav': minimizeNav }">
    <div id="left-container">
      <Sidebar/>

        <div class="text-center mb-3" v-if="$store.getters['account/accountData'].role === 'caleg'">
          <img src="/assets/images/logo-kpu.png" style="max-width: 156px;" alt="">
        </div>
      <p class="m-0 text-center text-light" style="font-size: 11px;">Copyright 2024<br>Gradient Creative Publishing</p>
    </div>
    <div id="right-container">
      <Navbar @onToggleClick="toggleMinimizeNav"/>
      <Content/>
    </div>
    <!-- <notifications/> -->
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import Navbar from '@/components/Navbar'
import Content from './Content'
import "vue-select/dist/vue-select.css";

let isMobile = false;
if( screen.width <= 780 ) {
  isMobile = true
}

export default {
  name: 'general-screen-dashboard',
  components: {
    Sidebar,
    Navbar,
    Content
  },
  data () {
    return {
      minimizeNav: isMobile ? true : localStorage.getItem("minimizeNav") === null ? false : (localStorage.getItem("minimizeNav") === "true")
    }
  },
  watch:{
    $route (to, from){
      if (isMobile) {
        this.minimizeNav = true;
      }
    }
  },
  mounted () {
    // console.log(this.minimizeNav)
  },
  methods: {
    toggleMinimizeNav () {
      localStorage.setItem("minimizeNav", !this.minimizeNav);
      this.minimizeNav = !this.minimizeNav;
    }
  }
}
</script>
